.change_password {
  width: 480px;
}
.buttons {
  display: grid;
  align-items: center;
  /* justify-content: center; */
  grid-template-columns: 71px 74px;
  gap: 24px;
  margin-top: 20px;
  padding-left: 2px;
}
.textfield_label {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05000000074505806px;
  text-align: left;
  color: #7f7d83;
  margin: 0px 0px 7px 3px;
}
@media screen and (max-width: 500px) {
  .change_password {
    width: auto;
  }

  .buttons{
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }
}

.error-page {
    width: 100%;
    height: 100%;
    padding: 20px;
  }
  
  .error-page.medium-down {
    padding: 2px;
    height: fit-content;
  }
  
  .error-page .error-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .error-page.medium-down .error-container {
    flex-direction: column-reverse;
  }
  
  .error-page .error-container .button-text-container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
  }
  
  .error-page.medium-down .error-container .button-text-container {
    width: 100%;
  }
  
  .error-page .error-container .button-text-container .heading {
    width: 80%;
    text-align: center;
  }
  
  .error-page .error-container .button-text-container .heading .head {
    font-size: 45px;
    color:rgb(67, 65, 65);
  }
  
  .error-page .error-container .button-text-container .heading .head span {
    color:var(--blue-text-color);
    font-size: 60px;
  }
  
  .error-page .error-container .button-text-container .button-container .button {
    background-color: var(--blue-text-color);
  }
  
  .error-page .error-container .error-image-container {
    width: 50%;
    height: 100%;
  }
  
  .error-page.medium-down .error-container .error-image-container {
    width: 100%;
  }
  
  .error-page .error-container .error-image-container img {
    width: 100%;
    height: 100%;
  }
  
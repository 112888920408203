@import url("./../../Globals/colors.css");
.button_with_border {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1.5px solid;
  font-size: 17px;
  font-weight: 400;
  color: var(--button-background-color);
  border-color: var(--button-background-color);
  /* border: none; */
  background-color: white !important;
  cursor: pointer;
  font-family: Inter!important;
font-size: 14px!important;
font-weight: 500!important;
line-height: 20px!important;
letter-spacing: -0.05000000074505806px!important;
text-align: center!important;
}
.button_with_background {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 400;
  color: var(--color-button-text);
  background-color: var(--button-background-color);
  border: none;
  cursor: pointer;
  font-family: Inter!important;
font-size: 14px!important;
font-weight: 500!important;
line-height: 20px!important;
letter-spacing: -0.05000000074505806px!important;
text-align: center!important;

}
.button_with_icon {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 400;
  color: var(--color-button-text);
  background-color: var(--button-background-color);
  /* border: none; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid;
  border-color: rgb(212, 205, 205);
  font-family: Inter!important;
font-size: 14px!important;
font-weight: 500!important;
line-height: 20px!important;
letter-spacing: -0.05000000074505806px!important;
text-align: center!important;
}
.header_menu_button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: -0.019999999552965164px;
text-align: left;
  color: black;
  background-color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: start;
  gap: 20px;
  font-family: Inter!important;
font-size: 14px!important;
font-weight: 400!important;
line-height: 20px!important;
letter-spacing: -0.05000000074505806px!important;
text-align: center!important;
}
.header_menu_button_selected {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 400;
  color: black;
  background-color: var(--blue-fad-color);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: start;
  gap: 10px;
  font-family: Inter!important;
font-size: 14px!important;
font-weight: 500!important;
line-height: 20px!important;
letter-spacing: -0.05000000074505806px!important;
text-align: center!important;
}
.header_menu_button:hover {
  color: black;
  background-color: var(--blue-fad-color);
  font-family: Inter!important;
font-size: 14px!important;
font-weight: 500!important;
line-height: 20px!important;
letter-spacing: -0.05000000074505806px!important;
text-align: center!important;
}

.dashboard {
  width: 100vw;
  height: 100vh;
}
.dashboard_container {
  width: 100%;
  height: calc(100% - 65px);
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 12px;
  padding: 12px;
}
.dashboard_menu {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background:var(--blue-new) !important;
  border-radius: 15px;
  padding: 12px;
  gap: 6px;
}
.dashboard_screen {
  height: 100%;
  background: var(--color-gray-background);
  border-radius: 15px;
  padding: 12px;
}
.dashboard_menu_button_selected {
  border: none !important;
  background-color: var(--light-blue) !important;
  border-radius: 15px !important;
  width: 100% !important;
  text-transform: none !important;
  height: 40px !important;
  font-size: 17px !important;
  text-align: start !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  color: var(--blue-new) !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 4.3px!important;
  padding: 5px 12px !important;
  letter-spacing: 0.2px!important;
}
.dashboard_menu_button {
  border: none !important;
  /* background-color: var(--blue-fad-color)!important; */
  border-radius: 15px !important;
  width: 100% !important;
  text-transform: none !important;
  height: 40px !important;
  font-size: 16px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 5px 12px !important;
  gap: 4.3px!important;
  letter-spacing: 0.2px!important;
}
.dashboard_menu_button:hover {
  border: none !important;
  background-color: var(--light-blue) !important;
  border-radius: 15px !important;
  width: 100% !important;
  text-transform: none !important;
  height: 40px !important;
  font-size: 16px !important;
  text-align: start !important;
  color: #999999 !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  color: var(--blue-new) !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 5px 12px !important;
  letter-spacing: 0.2px!important;
}
.dashboard_menu_logout_button {
  border: none !important;
  background-color: #fa6e6e !important;
  border-radius: 15px !important;
  width: 98% !important;
  text-transform: none !important;
  height: 40px !important;
  font-size: 17px !important;
  text-align: start !important;
  color:#fff !important;
  position: absolute !important;
  bottom: 10px;
}

@media screen and (max-width: 500px) {
  .dashboard {
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .dashboard_container {
    display: flex;
    flex-direction: column-reverse;
  }
  .dashboard_screen {
    flex: 1;
    padding-top: 0;
    padding: 18px;
    margin: 12px;
    overflow: scroll;
    width: 100%;
  }

  .dashboard_menu {
    /* flex-direction: row;
    height: auto;
    margin: 8px 15px;
    padding: 10px; */
    flex-direction: row;
    height: 43px;
    margin: 0px 47px;
    padding: 10px;
    width: 285px;
    overflow: scroll;
  }
  .dashboard_menu_button {
    height: 60px !important;
    flex-direction: column;
    font-size: 12px !important;
    font-family: inherit !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .dashboard_menu_button_selected {
    height: 60px !important;
    flex-direction: column;
    font-size: 12px !important;
    font-family: inherit !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .dashboard_menu_logout_button {
    display: none !important;
  }
  .MuiButton-startIcon {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

:root {
  --color-black: #222;
  --color-button-text: #fff;
  --color-white-container: #fff;
  --backGround-color: #f8f8f8;
  --button-background-color: #006bff;
  --blue-text-color: #006bff;
  --gray-text-color: rgb(71, 68, 68);
  --blue-fad-color: #0909bd0d;
  --color-red: #cc0000;
  --color-gray-background: #f8f8f8;
  --color-gray-text: #999999;
  --dark-gray-text: #7d7979;
  --color-green-status: #72e128;
  --fad-backgroud: #f2f2f9a0;
  --blue-new: rgba(0, 107, 255, 1);
  --light-blue: rgba(235, 242, 255, 1);
}

.header {
  padding: 10px 32px 10px 32px;
  height: 64px;
  border-bottom: 1px solid rgba(239, 240, 242, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header_logo {
  /* height: 23px; */
  cursor: pointer;
  max-width: 130px;
  max-height: 50px;
  /* align-self: center; */
}
.header_containt {
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
  cursor: pointer;
}
.header_user_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header_username {
  font-family: Inter;
  font-size: 14px;
  font-weight: 501;
  line-height: 20px;
  letter-spacing: -0.019999999552965164px;
  text-align: left;
  margin: 0;
}
.header_useremail {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05000000074505806px;
  text-align: left;
  margin: 0;
  color: #7f7d83;
}
.header_menu_list {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 201px;
  padding: 20px 15px;
  background-color: white;
  border-radius: 15px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  height: auto;
  padding: 10px;
}
.keyboardArrowDownIconOpenList{
  transform: rotate(0deg); 
  transform-origin: left top;
}
.keyboardArrowDownIcon{
  transform: rotate(180deg); 
  transform-origin: left top
}

@media screen and (max-width: 500px) {
  .header_logo {
    /* height: 23px; */
    cursor: pointer;
    max-width: 150px;
    max-height: 50px;
    /* align-self: center; */
  }
  .header {
    padding: 10px 15px;
  }
  .header_user_container {
    display: none;
  }
}

.text_field_simple {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border-radius: 7px;
  padding: 0px 10px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: unset;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 0.1rem solid #DCDCDE;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  height: 40px;
  font-size: 16px;
  box-shadow: 0px 1.5px 4px -1px #0A090B12;

}
.text_field_simple:hover {
  border-color: #275efe;
}
.text_field_simple:focus {
  box-shadow: 0 0 0 2px rgba(39, 94, 254, 0.3);
  border-color: #275efe;
}
.text_field_simple_container {
    width: 99%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.text_field_simple_error {
  margin: 0;
  margin-bottom: 10px;
  padding-left: 3px;
  color: #CC0000;
  font-size: 13px;
  font-weight: 400;
}


.text_field_password {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    border-radius: 7px;
    padding: 0px 50px 0px 10px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: unset;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 0.1rem solid #DCDCDE;
    background: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 40px;
    font-size: 16px;
    box-shadow: 0px 1.5px 4px -1px #0A090B12;
  }
  .text_field_password:hover {
    border-color: #275efe;
  }
  .text_field_password:focus {
    box-shadow: 0 0 0 2px rgba(39, 94, 254, 0.3);
    border-color: #275efe;
  }
  .text_field_password_container {
      width: 100%;
      position: relative;
  }
  .text_field_password_error {
    margin: 0px 0px 5px 0px;
    padding-left: 3px;
    color: #CC0000;
    font-size: 13px;
    font-weight: 400;
    margin-top: 6px;
  }
  .text_field_icon{
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgb(77, 74, 74);
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    .text_field_password {
      width: 100%;
      padding: 0px 38px 0px 10px;
    }
    .text_field_simple {
      width: 100%;

    }
  }
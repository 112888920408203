.salary-slip{
    margin: 15px;    
}
.empDetail {
    width: 100%;
    text-align: left;
    border: 2px solid black;
    border-collapse: collapse;
    table-layout: fixed;
  }
  
  .head {
    margin: 10px;
    margin-bottom: 50px;
    width: 100%;
  }
  
  .companyName {
    text-align: right;
    font-size: 25px;
    font-weight: bold;
  }
  
  .salaryMonth {
    text-align: center;
  }
  
  .table-border-bottom {
    border-bottom: 1px solid;
  }
  
  .table-border-right {
    border-right: 1px solid;
  }
  
  .myBackground {
    padding-top: 10px;
    text-align: left;
    border: 1px solid black;
    height: 40px;
  }
  
  .myAlign {
    text-align: center;
    border-right: 1px solid black;
  }
  
  .myTotalBackground {
    padding-top: 10px;
    text-align: left;
    background-color: #EBF1DE;
    border-spacing: 0px;
  }
  
  .align-4 {
    width: 25%;
    float: left;
  }
  
  .tail {
    margin-top: 35px;
  }
  
  .align-2 {
    margin-top: 25px;
    width: 50%;
    float: left;
  }
  
  .border-center {
    text-align: center;
  }
  .border-center th, .border-center td {
    border: 1px solid black;
  }  
  th, td {
    padding-left: 6px;
  }
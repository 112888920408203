.toggle_button {
  border-bottom: 1px solid #cec9c952;
  padding: 10px 10px 10px 10px;
  width: 100%;
  cursor: pointer;
  color: rgb(167, 160, 160);
  font-size: 14px !important;
  font-family: inherit !important;
  text-align: center!important;
}
.toggle_button_selected {
  border-bottom: 3px solid rgb(11, 89, 215);
  padding: 10px 10px 10px 10px;
  width: 100%;
  color: rgb(11, 89, 215) !important;
  cursor: pointer;
  color: rgb(167, 160, 160);
  font-size: 14px !important;
  font-family: inherit !important;
  text-align: center!important;
}
.toggle_button:hover {
  border-bottom: 3px solid rgb(11, 89, 215);
  padding: 10px 10px 10px 10px;
  width: 100%;
  color: rgb(11, 89, 215) !important;
  cursor: pointer;
  color: rgb(167, 160, 160);
  font-size: 14px !important;
  font-family: inherit !important;
  text-align: center!important;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.05000000074505806px;
  text-align: left;
  color: #4F4D55;
}
.dropdown_select {
    border-radius: 10px!important;
    border-color: #4F4D55;
    /* width: 70px; */
}


@media screen and (max-width: 500px) {
  .dropdown{
    display: none;
  }
}
.loading_page{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#f8f8f86d;
}
.setting {
  width: 99%;
  height: 97%;
  /* margin: 20px 15px; */
  display: grid;
  grid-template-rows: 70px 1fr;
}
.toggle_buttons {
  width: 300px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  height: 60px;
}

.show_screen {
  background-color: white;
  width: 95%;
  /* max-height: 80%; */
  /* min-height: 50%; */
  height: 103%;
  width: 101%;
  padding: 40px 20px;
  border-radius: 15px;
  overflow-y: auto !important;
}

@media screen and (max-width: 500px) {
  .setting {
    width: auto;
    margin: 0;
  }

  .show_screen {
    width: auto;
    padding: 15px 15px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 500px) {
  .css-1d6wzja-MuiButton-startIcon {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .KeyboardArrowDownIcon {
    display: none !important;
  }
}
.css-1fw5qro-MuiPaper-root{
  box-shadow: none!IMPORTANT;
}
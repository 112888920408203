.account_details {
  width: 100%;
  /* padding: 20px; */
  height: 100%;
  /* overflow-x: scroll; */
}
.account_details_container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.buttons {
  display: grid;
  align-items: center;
  grid-template-columns: 120px 120px;
  gap: 20px;
  margin-top: 20px;
  padding-left: 2px;
}
.textfield_label {
  font-family: Inter;
  font-size: 14.3px;
  font-weight: 400.2;
  line-height: 20px;
  letter-spacing: -0.05000000074505806px;
  text-align: left;
  color: #7F7D83;
  margin: 0px 0px 7px 3px;
  
}

@media screen and (max-width: 500px) {
  .account_details_container{
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .buttons{
    display: flex;
    margin: 0;
  }
}
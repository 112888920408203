.queue_details {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}
.queue_details_loading {
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  flex-direction: column;
  grid-template-rows: 100px 100px 100px;
  gap: 7px;
  justify-content: center;
  grid-auto-columns: 1fr;
}
.queue_loading{
  width: 97%;
  height: 150px!important;
  margin: 0;
  margin-left: 15px;
}
.meet_history {
  width: 100%;
  background-color: white;
  /* margin: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 25px 0px;
  border-radius: 10px;
}

.meet_history_heading {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.019999999552965164px;
  width: 90%;
  align-self: start;
  margin-left: 20px;
}
.meet_history_table {
  width: 99%;
  /* height: 100%; */
}
.meet_history_pagination {
  height: 60px;
  background-color: white;
  display: grid;
  align-items: center;
  /* justify-content: space-between; */
  grid-template-columns: 240px 1fr;
  width: 97%;
  align-self: start;
  margin-left: 20px;
}

@media screen and (max-width: 500px) {
  .meet_history{
    padding: 15px 0;
    margin: 0;
    width: auto;
  }

  .meet_history_pagination{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
}

.CallLogsLayout {
  display: flex;  
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  background-color: white;
  margin: 7px 10px;
  margin-top: 0;
  margin-bottom: 12px;
  padding: 0px 20px;
  border-radius: 10px;
  height: 88px;
}
.CallLogsLayout_avatar {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 200px;
  gap: 15px;
}
.CallLogsLayout_details {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 8px;
  flex-direction: column;
}
.CallLogsLayout_timers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.CallLogsLayout_stats {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;  
}
.CallLogsLayout_stats_heading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;  
  text-align: left;
  margin: 0;
  margin-bottom: 11px;
  text-align: center;
}
.CallLogsLayout_header {
  font-family: Inter;  
  font-weight: 700;
  line-height: 16px;  
  text-align: left;
  margin: 0;
}
.CallLogsLayout_title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 401;  
  margin: 0;
  text-align: center
}

.buttons {
  display: grid;
  align-items: center;
  justify-content: end;
  grid-template-columns: 65px;
  gap: 30px;
  /* width: 65px; */
}
.queue_status_online {
  width: 54px;
  height: 24px;
  text-align: center;
  background-color: var(--color-green-status);
  padding: 5px;
  border-radius: 100px;
  color: white;
  font-weight: 500;
  font-size: 13px;
}
.queue_status_offline {
  width: 54px;
  height: 24px;
  text-align: center;
  background-color: var(--color-gray-text);
  padding: 5px;
  border-radius: 100px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  font-weight: 700;
}

@media screen and (max-width: 500px) {
  .CallLogsLayout {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 18px 12px;
    margin-bottom: 15px !important;
    min-height: 250px;
    max-width: 300px;
  }

  .CallLogsLayout_avatar {
    flex-direction: column;
    gap: 0;
  }
  .CallLogsLayout_timers {
    margin: 15px 0;
  }
  .buttons {
    /* width: 100%; */
    justify-content: initial;
    /* display: flex; */
    align-items: center;
    grid-template-columns: auto;
    width: 65px !important;
  }

  .CallLogsLayout_stats_heading {
    font-size: 12px;
    margin: 0;
    margin: 8px 0;
  }

  .queue_status_offline {
    margin: 0;
    margin-bottom: 10px;
  }
  .CallLogsLayout_details{
    align-items: center;
  }
}
